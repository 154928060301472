module services {
    export module master {
        export class termsOfPaymentService implements interfaces.master.ITermsOfPaymentService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            //Gets list of all Terms Of Payment for a dropdown/autocomplete list
            getTermsOfPaymentDropdown(ownerEntityId: number, searchText: string): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "TermsOfPayment/GetDropdownList", {
                    ownerEntityId: ownerEntityId,
                    searchText: searchText
                    }, {
                            query: {
                                method: 'GET',
                                isArray: true
                            }
                        });
            }

            getTermsOfPaymentDefaults(termsOfPaymentId: number): ng.resource.IResourceClass<interfaces.master.ITermsOfPaymentDefaults> {
                return this.$resource<interfaces.master.ITermsOfPaymentDefaults>(this.ENV.DSP_URL + "TermsOfPayment/GetTermsOfPaymentDefaults", {
                    termsOfPaymentId: termsOfPaymentId
                    }, {
                            query: {
                                method: 'GET',
                                isArray: false
                            }
                        });
            }

        }
    }
    angular.module("app").service("termsOfPaymentService", services.master.termsOfPaymentService);
}